document.addEventListener("DOMContentLoaded", () => {
  function init(form) {
    if (form.hasAttribute("data-auto-submit-init-started")) {
      return;
    }
    form.setAttribute("data-auto-submit-init-started", true);

    let rawDelay = "0s";
    if (form.hasAttribute("data-auto-submit-delay")) {
      rawDelay = form.getAttribute("data-auto-submit-delay");
    }
    const delay = htmx.parseInterval(rawDelay);

    const timeoutId = window.setTimeout(() => {
      form.submit();
    }, delay);

    if (form.hasAttribute("data-auto-submit-cancel-on-hover")) {
      let selectors = form.getAttribute("data-auto-submit-cancel-on-hover");
      if (["", "true"].includes(selectors.toLowerCase())) {
        form.addEventListener("mouseover", () => {
          clearTimeout(timeoutId);
        });
      } else {
        document.querySelectorAll(selectors).forEach((cancelOnHover) => {
          cancelOnHover.addEventListener("mouseover", () => {
            clearTimeout(timeoutId);
          });
        });
      }
    }
  }

  const selectors =
    "[data-auto-submit], [data-auto-submit-delay], [data-auto-submit-cancel-on-hover]";

  document.querySelectorAll(selectors).forEach(init);

  document.addEventListener("htmx:afterProcessNode", (event) => {
    event.detail.elt.querySelectorAll(selectors).forEach(init);
  });
});
